interface IRadioButtonProps {
  label: string
  checked: boolean
  onChange: () => void
}

export const RadioButton = ({ label, checked, onChange }: IRadioButtonProps) => {
  return (
    <label
      className={`flex cursor-pointer items-center ${
        checked ? 'bg-gradient-to-r from-yellow-450 to-[#EC64A6]' : 'border border-normal bg-white'
      } h-10 rounded-full px-3 lg:h-auto lg:px-4 lg:py-2`}
    >
      <input type="radio" checked={checked} onChange={onChange} className="hidden" />
      <span
        className={`text-[15px] lg:text-base ${checked ? 'font-semibold text-white' : 'text-gray-500'}`}
      >
        {label}
      </span>
    </label>
  )
}
